import fp from 'lodash/fp';

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const PDF_FORMAT = 'application/pdf';

export const USER_GROUP_CSV = 'group_members.csv';

export const DEAL_SALE_CSV = 'deal_sales.csv';

export const SUPPORTED_FORMATS_AND_PDF = [...SUPPORTED_FORMATS, PDF_FORMAT];

export const isValidFileFormat = fp.curry(
  (value, formats = SUPPORTED_FORMATS) => fp.includes(value)(formats),
);

export const isValidFileFormatWithPDF = fp.curry(
  (value, formats = SUPPORTED_FORMATS_AND_PDF) => fp.includes(value)(formats),
);

export const isValidPDFFileFormat = fp.curry((value, formats = PDF_FORMAT) =>
  fp.includes(value)(formats),
);

export const isValidFileSize = fp.curry(
  (size, FILE_SIZE = 5 * 1024 * 1024) => (size as number) <= FILE_SIZE,
);
