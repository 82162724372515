import { Box } from '@chakra-ui/react';
import { MoneyField, PercentageField } from 'components/inputs';
import { Form, Formik, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { ICommonDealStateProps } from 'types.d';

import { FinancialFormValidationSchema } from './helpers';

export const MarketFinancialForm = forwardRef<
  FormikProps<IDealPayload>,
  ICommonDealStateProps
>((props, ref): JSX.Element => {
  const { initialValues = {}, onSubmit, ...rest } = props;

  const { t } = useTranslation('deals');

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={FinancialFormValidationSchema}
    >
      <Box as={Form} {...rest}>
        <MoneyField
          label={t('forms.input.funding-goal.label')}
          mb={4}
          name="funding_goal"
          size="md"
          w={{ base: '100%', lg: '66%' }}
        />

        <MoneyField
          label={t('forms.input.min-funding-goal.label')}
          mb={4}
          name="min_funding_goal"
          size="md"
          w={{ base: '100%', lg: '66%' }}
        />

        <MoneyField
          label={t('forms.input.min-investment.label')}
          mb={4}
          name="min_investment"
          size="md"
          w={{ base: '100%', lg: '33%' }}
        />

        <PercentageField
          label={t('forms.input.fee.label')}
          mb={4}
          name="fee"
          size="md"
          w={{ base: '100%', lg: '33%' }}
        />

        <PercentageField
          label={t('forms.input.carry.label')}
          name="carry"
          size="md"
          w={{ base: '100%', lg: '33%' }}
        />
      </Box>
    </Formik>
  );
});

MarketFinancialForm.displayName = 'MarketFinancialForm';
