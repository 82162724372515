import { Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { Td, TdActions } from 'components/lib';
import { CustomTable } from 'components/modules';
import { getId, getResults, isResultsEmpty } from 'helpers';
import { nanoid } from 'nanoid';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { IDealResource } from 'services/resources/deal-flow/deal/types.d';

import {
  getCreated,
  getIndustries,
  getName,
  getSlug,
  HEADERS,
} from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const { active, defaultRestore, onPrepareDelete, state } =
    useGenericContext<IDealResource>();

  const history = useHistory();

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IDealResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getName(row)}</Td>
            <Td>{getIndustries(row)}</Td>
            <Td>{getSlug(row)}</Td>
            <Td>{getCreated(row)}</Td>
            <TdActions
              isActive={active}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => history.push(`/deals/${row?.id}`)}
              customButtonBg="green"
              customButtonIcon={<Icon as={FaCheck} color="white" />}
              onCustomCallback={() =>
                history.push(`/second-market/metrics/${row?.id}`)
              }
            />
          </Tr>
        ))}
      </CustomTable>
    </>
  );
};
