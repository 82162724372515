/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Heading,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DealResources } from 'services/resources/deal-flow/deal';
import { IDealResource } from 'services/resources/deal-flow/deal/types.d';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

interface MetricsProps {
  totalCapitalRaised: number;
  fundingTarget: number;
  totalInvestors: number;
  closingDate: string;
  roundStatus: string;
}

export const ClosedRoundMetrics: React.FC<MetricsProps> = (): JSX.Element => {
  const fundingTarget = 5;
  const roundStatus = 'h';

  const { deal_id } = useParams<{ deal_id: string }>();

  console.log('deal_id', deal_id);

  const [investments, setInvestments] = useState<IDealResource[]>([]);
  const [totalInvestors, setTotalInvestors] = useState(0);
  const [totalCapitalRaised, setTotalCapitalRaised] = useState(0);
  const [totalDepositadoMXN, setTotalDepositadoMXN] = useState(0);
  const [totalInversionStartupMXN, setTotalInversionStartupMXN] = useState(0);
  const [totalProrrataPercentage, setTotalProrrataPercentage] = useState(0);
  const [totalComisionInversionistaMXN, setTotalComisionInversionistaMXN] =
    useState(0);
  const [closingDate, setClosingDate] = useState('');
  const [slug, setSlug] = useState('');

  const deal_id_filter = Number(deal_id);

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const response = await DealResources.dealStatistics({
          deal_id: deal_id_filter,
        });
        console.log('response', response);
        const investmentsData = response.data.results;

        setInvestments(investmentsData);
        setTotalInvestors(investmentsData.length);

        setInvestments(investmentsData);
        // setTotalInvestors(investmentsData.length);
        const totalRaised = investmentsData.reduce(
          (sum, investment) => sum + (investment.amount ?? 0),
          0,
        );
        setTotalCapitalRaised(totalRaised);
        const totalDepositado = investmentsData.reduce(
          (sum, investment) => sum + (investment.total_deposit ?? 0),
          0,
        );
        setTotalDepositadoMXN(totalDepositado);
        const totalInversion = investmentsData.reduce(
          (sum, investment) => sum + (investment.total_investment_amount ?? 0),
          0,
        );
        setTotalInversionStartupMXN(totalInversion);

        const totalProrrata = investmentsData.reduce(
          (sum, investment) =>
            sum + parseFloat(String(investment.prorrata_percentage ?? '0')),
          0,
        );
        setTotalProrrataPercentage(totalProrrata);

        const totalComision = investmentsData.reduce(
          (sum, investment) =>
            sum +
            ((investment.total_deposit ?? 0) -
              (investment.total_investment_amount ?? 0)),
          0,
        );
        setTotalComisionInversionistaMXN(totalComision);

        if (investmentsData.length > 0) {
          const dealData = investmentsData[0].deal;
          if (typeof dealData === 'object' && 'closing_date' in dealData) {
            setClosingDate(dealData.closing_date ?? '');
          }
          if (
            typeof dealData === 'object' &&
            dealData !== null &&
            'slug' in dealData
          ) {
            if (dealData && 'slug' in dealData) {
              setSlug(dealData.slug ?? '');
            }
          }
        }
      } catch (error) {
        console.error('Error fetching investments:', error);
      }
    };

    fetchInvestments();
  }, []);

  return (
    <Box p={6} maxW="1200px" mx="auto">
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        Métricas de rondas de inversión cerrada - {slug}
      </Heading>
      <Box display="flex" flexDirection="column" alignItems="center">
        <SimpleGrid columns={[1, null, 3]} spacing={10}>
          <Stat>
            <StatLabel>Capital total recaudado</StatLabel>
            <StatNumber>
              ${totalInversionStartupMXN.toLocaleString()}
            </StatNumber>
            {/* <StatHelpText>
              Funding Target: ${fundingTarget.toLocaleString()}
            </StatHelpText> */}
          </Stat>

          <Stat>
            <StatLabel>Total de inversionistas</StatLabel>
            <StatNumber>{totalInvestors}</StatNumber>
            {/* <StatHelpText>As of closing date</StatHelpText> */}
          </Stat>

          <Stat>
            <StatLabel>Fecha de cierre</StatLabel>
            <StatNumber>
              {new Date(closingDate).toLocaleDateString()}
            </StatNumber>
            {/* <StatHelpText>Status: {roundStatus}</StatHelpText> */}
          </Stat>
        </SimpleGrid>

        <Box mt={10} width="100%">
          <Heading as="h3" size="md" mb={4}>
            Detalles de participaciones
          </Heading>
          <Box overflowX="auto" width="100%">
            <Table variant="simple" minWidth="1200px">
              <Thead>
                <Tr>
                  <Th minWidth="250px">Inversionista</Th>
                  {/* <Th>Fecha Contrato</Th> */}
                  <Th minWidth="150px">Fecha de Compromiso</Th>
                  <Th minWidth="150px">Fecha Depósito</Th>
                  <Th minWidth="150px">Total Depositado MXN</Th>
                  <Th minWidth="150px">Inversión a Startup MXN</Th>
                  <Th minWidth="150px">Comisión Inversionista MXN</Th>
                  <Th minWidth="150px">% Pro Rata del Deal</Th>
                  {/* <Th>Factura</Th> */}
                  <Th minWidth="150px">Mail</Th>
                  <Th minWidth="150px">RFC</Th>
                  {/* <Th minWidth="150px">CP</Th>
                  <Th minWidth="150px">Estado</Th> */}
                  <Th minWidth="150px">Comentarios</Th>
                </Tr>
              </Thead>
              <Tbody>
                {investments.map((investment) => (
                  <Tr key={investment.id}>
                    <Td minWidth="250px">
                      {typeof investment.user === 'object' &&
                      'kyc_physical_person' in investment.user &&
                      typeof investment.user.kyc_physical_person === 'object'
                        ? `${investment.user.kyc_physical_person.name ?? ''} ${
                            investment.user.kyc_physical_person
                              ?.first_surname ?? ''
                          } ${
                            investment.user.kyc_physical_person?.last_surname ??
                            ''
                          }`
                        : 'N/A'}
                    </Td>
                    {/* <Td>Fecha Contrato</Td> */}
                    <Td minWidth="150px">
                      {investment.agreement_date
                        ? new Date(
                            investment.agreement_date,
                          ).toLocaleDateString()
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">
                      {investment.deposit_date
                        ? new Date(investment.deposit_date).toLocaleDateString()
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">
                      ${investment.total_deposit?.toLocaleString()}
                    </Td>
                    <Td minWidth="150px">
                      ${investment.total_investment_amount?.toLocaleString()}
                    </Td>
                    <Td minWidth="150px">
                      $
                      {(
                        Number(investment.total_deposit ?? 0) -
                        Number(investment.total_investment_amount ?? 0)
                      ).toLocaleString()}
                    </Td>
                    <Td minWidth="150px">
                      {`${parseFloat(
                        `${investment.prorrata_percentage ?? '0'}`,
                      ).toFixed(2)} %`}
                    </Td>
                    {/* <Td>Factura</Td> */}
                    <Td minWidth="150px">
                      {typeof investment.user === 'object' &&
                      'email' in investment.user
                        ? investment.user.email
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">
                      {typeof investment.user === 'object' &&
                      'kyc_physical_person' in investment.user &&
                      investment.user.kyc_physical_person
                        ? (
                            investment.user
                              .kyc_physical_person as IKYCPhysicalResource
                          ).tax_id
                        : 'N/A'}
                    </Td>
                    {/* <Td minWidth="150px">
                      {typeof investment.user === 'object' &&
                      'kyc_physical_person' in investment.user &&
                      investment.user.kyc_physical_person
                        ? (
                            investment.user
                              .kyc_physical_person as IKYCPhysicalResource
                          ).zip_code
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">
                      {investment.is_active ? 'Activo' : 'Inactivo'}
                    </Td> */}
                    <Td minWidth="150px">{investment.comments}</Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Td colSpan={3}>Total</Td>
                  <Td>{totalDepositadoMXN.toLocaleString()}</Td>
                  <Td>{totalInversionStartupMXN.toLocaleString()}</Td>
                  <Td>{totalComisionInversionistaMXN.toLocaleString()}</Td>
                  <Td>{totalProrrataPercentage.toFixed(2)}</Td>
                </Tr>
              </Tfoot>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
