import { Route, Switch, useRouteMatch } from 'react-router-dom';

import {
  ClosedRoundMetrics,
  CreatePage,
  ListingPage,
  SaleDealMetrics,
} from './pages';

export const MarketPages: React.FC = (): JSX.Element => {
  const { path } = useRouteMatch();

  console.log('PATH CONTENT', path);

  return (
    <Switch>
      <Route component={ListingPage} exact path={path} />
      <Route component={CreatePage} path={`${path}/create/`} />
      {/* <Route component={UpdatePage} path={`${path}/:slug`} /> */}
      <Route component={ClosedRoundMetrics} path={`${path}/metrics/:deal_id`} />
      <Route
        component={SaleDealMetrics}
        path={`${path}/sale-metrics/:deal_id`}
      />
    </Switch>
  );
};
