import { Box, FormLabel, Select } from '@chakra-ui/react';
import { MoneyField, PercentageField } from 'components/inputs';
import { table } from 'console';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SaleTable } from './SaleTable';

export const ShareBuybackApplicant = (props) => {
  const {
    combinedExit = false,
    saleAmountIntput = true,
    saleValueSM = 0,
    saleValueRebuy = 0,
  } = props;
  const { setFieldValue, values } = useFormikContext<any>();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    console.log('values in buyback', values);
    setFieldValue('sale_type', 'buyback'); // Set sale_type to "buyback" only on mount
  }, []);

  const { t } = useTranslation('deals');

  const parseCurrency = (value) => Number(value.replace(/[^0-9.-]+/g, ''));

  const changeHandler = (e) => {
    console.log('e', parseCurrency(e));
    setFieldValue('sales_value', parseCurrency(e));
  };

  function handleTableDataChange(newData) {
    setTableData(newData);
  }

  return (
    <Box>
      <Box>
        <FormLabel textAlign="left">Porcentaje de venta</FormLabel>
        <PercentageField
          name="sale_percentage"
          size="md"
          w={{ base: '100%', lg: '33%' }}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value.replace('%', '');
            setFieldValue('sales_percentage', value);
          }}
        />
      </Box>
      {saleAmountIntput ? (
        <Box>
          <FormLabel textAlign="left">Monto total de venta</FormLabel>
          <MoneyField
            mb={4}
            name="sales_value"
            size="md"
            w={{ base: '100%', lg: '66%' }}
            onChange={(e) =>
              changeHandler((e.target as HTMLInputElement).value)
            }
          />
        </Box>
      ) : null}

      <Box>
        <FormLabel>Tipo de salida</FormLabel>
        <Select
          name="exit_type"
          placeholder="Tipo de salida"
          onChange={(e) => setFieldValue('exit_type', e.target.value)}
          disabled={!values.sales_value || Number(values.sales_value) <= 0} // Disable based on sale_value
        >
          <option value="total">Total</option>
          <option value="parcial">Parcial</option>
        </Select>
      </Box>

      {values.exit_type === 'total' && (
        <SaleTable
          combinedExit={combinedExit}
          saleValueSM={saleValueSM}
          saleValueRebuy={saleValueRebuy}
          config={{
            saleType: 'buyback',
            tableType: 'total',
          }}
        />
      )}
      {values.exit_type === 'parcial' && (
        <SaleTable
          combinedExit={combinedExit}
          saleValueSM={saleValueSM}
          saleValueRebuy={saleValueRebuy}
          config={{
            saleType: 'buyback',
            tableType: 'parcial',
          }}
        />
      )}
    </Box>
  );
};
