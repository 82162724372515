import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { QuerySuggestions } from 'components/elements';
import { SelectField } from 'components/inputs';
import { useFormikContext } from 'formik';
import { dealSalesTransform } from 'helpers/transform-helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DealResources } from 'services/resources/deal-flow/deal';
import { ISelectOption } from 'types.d';

import { ShareBuybackApplicant } from './components';
import CombinedExit from './components/CombinedExit';
import SecondaryMarket from './components/SecondaryMarket';

export const MarketGeneralForm = (props) => {
  // useEffect(() => {
  //   // Fetch exchange rate from Banxico API
  //   const fetchExchangeRate = async () => {
  //     try {
  //       const response = await axios.get(
  //         'https://cors-anywhere.herokuapp.com/https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos',
  //         {
  //           headers: {
  //             'Bmx-Token':
  //               '149aa093ffcf55187951b49938e3999f3d33bac295626925e4d5c6f471ed238e',
  //             Accept: 'application/json',
  //           },
  //         },
  //       );

  //       const seriesData = response.data.bmx.series[0].datos;
  //       const recentData = seriesData[seriesData.length - 1];
  //       const recentExchangeRate = recentData.dato;

  //       setUsdExchangeRate(parseFloat(recentExchangeRate));
  //     } catch (error) {
  //       console.error('Error fetching exchange rate:', error);
  //     }
  //   };

  //   fetchExchangeRate();
  // }, []);

  const { t } = useTranslation('deals');

  const [selectedFlow, setSelectedFlow] = useState('recompra');

  // Handle option selection to set the flow of inputs
  const handleOptionSelect = (value) => setSelectedFlow(value);

  const { setFieldValue, values } = useFormikContext();

  return (
    <Box p={9} maxW="1000px" mx="auto">
      <QuerySuggestions
        query={DealResources.findAll}
        transform={dealSalesTransform}
      >
        {({ loading, value }) => (
          <SelectField
            isLoading={loading}
            label={t('form.input.deal.label')}
            mb={4}
            name="id"
            options={value as ISelectOption[]}
            placeholder="Select an option"
            onChange={(e) =>
              setFieldValue('id', (e.target as HTMLInputElement).value)
            }
          />
        )}
      </QuerySuggestions>
      <Tabs variant="enclosed" isLazy>
        <TabList>
          <Tab>Recompra</Tab>
          <Tab>Mercado Secundario</Tab>
          <Tab>Combinacion</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ShareBuybackApplicant combinedExit={false} />
          </TabPanel>
          <TabPanel>
            <SecondaryMarket combinedExit={false} />
          </TabPanel>
          <TabPanel>
            <CombinedExit values={{ paymentMethod: 'paypal' }} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Step 1: Select Option */}
      {/* <RadioGroup onChange={handleOptionSelect} value={selectedFlow}>
          <Stack direction="row">
            <Radio value="recompra">Recompra</Radio>
            <Radio value="mercado_secundario">Mercado Secundario</Radio>
            <Radio value="combined">Combinacion</Radio>
          </Stack>
        </RadioGroup> */}

      {/* Step 2: Conditional Input Flow Based on Option */}
      {/* {selectedFlow === 'recompra' && <ShareBuybackApplicant />}
        {selectedFlow === 'mercado_secundario' && <SecondaryMarket />}
        {selectedFlow === 'combined' && (
          <CombinedExit values={{ paymentMethod: 'paypal' }} />
        )} */}

      {/* <Button type="submit" mt={4}>
          Submit
        </Button> */}
    </Box>
  );
};

MarketGeneralForm.displayName = 'MarketGeneralForm';
