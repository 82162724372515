import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { assign, createMachine, StateMachine } from 'xstate';

import { DealMachineEvent } from '../events';
import { DealMachineNodes } from '../nodes';
import { DealMachineSchema } from '../schemas';

const CONTEXT = {
  id: 0,
  closing_usd_change: 0,
  sales_value: 0,
  percentage_of_assets: 0,
  buyer: '',
  exit_date: new Date().toISOString().split('T')[0],
};

export const getMarketMachine = (
  initial: string | symbol = DealMachineNodes.GENERAL,
): StateMachine<IDealPayload, DealMachineSchema, DealMachineEvent> =>
  createMachine({
    id: 'market',
    context: CONTEXT as IDealPayload,
    initial,
    states: {
      [DealMachineNodes.GENERAL]: {
        on: {
          NEXT: {
            target: DealMachineNodes.FINANCIAL,
            actions: assign((_, state) => ({ ...state })),
          },
        },
      },
      [DealMachineNodes.FINANCIAL]: {
        on: {
          NEXT: {
            target: DealMachineNodes.INVESTMENT_MEMO,
            actions: assign((_, state) => ({ ...state })),
          },
        },
      },
      [DealMachineNodes.INVESTMENT_MEMO]: {
        type: 'final',
      },
    },
  });
