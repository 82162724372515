import { Box, Button, FormLabel, Input, SimpleGrid } from '@chakra-ui/react';
import { QuerySuggestions } from 'components/elements';
import { SelectField } from 'components/inputs';
import { emailTransform } from 'helpers/transform-helpers';
import React, { useEffect } from 'react';
import { UserResources } from 'services/resources/users/user';
import { ISelectOption } from 'types';

export const BuyersTable = ({
  buyers,
  setBuyers,
  salesValue,
  setFieldValue,
}) => {
  const addBuyer = () => {
    setBuyers([...buyers, { user: null, percentage: 0, montoCompra: '' }]);
  };

  const removeBuyer = (index) => {
    setBuyers(buyers.filter((_, i) => i !== index));
  };

  const handlePercentageChange = (index, value) => {
    const newPercentage = Math.min(Math.max(value, 0), 100);
    const totalPercentage = buyers.reduce(
      (sum, buyer, i) => (i === index ? sum : sum + buyer.percentage),
      0,
    );

    if (totalPercentage + newPercentage > 100) {
      alert('La suma de los porcentajes no puede superar el 100 %');
      return;
    }

    setBuyers((prevBuyers) => {
      const updatedBuyers = [...prevBuyers];
      updatedBuyers[index].percentage = newPercentage;
      updatedBuyers[index].montoCompra = (
        salesValue *
        (newPercentage / 100)
      ).toFixed(2);

      setFieldValue('buyersRows', updatedBuyers); // Actualizar Formik
      return updatedBuyers;
    });
  };

  return (
    <Box mt={5} mb={5}>
      <FormLabel textAlign="center">Quién Compra</FormLabel>
      {buyers.map((buyer, index) => (
        <SimpleGrid key={index} columns={4} spacingX="40px" spacingY="20px">
          <Box height="50px">
            <QuerySuggestions
              params={{ is_accredited: true }}
              query={UserResources.findAll}
              transform={emailTransform}
            >
              {({ loading, value }) => (
                <SelectField
                  isLoading={loading}
                  name={`user-${index}`}
                  options={value as ISelectOption[]}
                  placeholder="Select a user"
                  onChange={(selected) => {
                    const updatedBuyers = [...buyers];
                    updatedBuyers[index].user = selected;
                    setBuyers(updatedBuyers);
                  }}
                />
              )}
            </QuerySuggestions>
          </Box>
          <Box height="50px">
            <Input
              type="number"
              placeholder="Enter percentage"
              value={buyer.percentage}
              onChange={(e) =>
                handlePercentageChange(index, parseFloat(e.target.value))
              }
            />
          </Box>
          <Box height="50px">{buyer.montoCompra}</Box>
          <Box height="50px">
            <Button colorScheme="red" onClick={() => removeBuyer(index)}>
              Remove
            </Button>
          </Box>
        </SimpleGrid>
      ))}
      <Button mt={4} colorScheme="blue" onClick={addBuyer}>
        Add Row
      </Button>
    </Box>
  );
};
