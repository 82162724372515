import { Box } from '@chakra-ui/react';
import { QuerySuggestions } from 'components/elements';
import {
  DateField,
  FileField,
  InputField,
  SelectField,
  TextField,
} from 'components/inputs';
import { Form, Formik, FormikProps } from 'formik';
import { genericTransform } from 'helpers/transform-helpers';
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyResources } from 'services/resources/deal-flow/company';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { DealStatusResources } from 'services/resources/deal-flow/deal-status';
import { ICommonDealStateProps, ISelectOption } from 'types.d';

import { GeneralFormValidationSchema } from './helpers';

export const GeneralForm = forwardRef<
  FormikProps<IDealPayload>,
  ICommonDealStateProps
>((props, ref): JSX.Element => {
  const { initialValues = {}, onSubmit, ...rest } = props;

  const { t } = useTranslation('deals');
  const [dealStatus, setDealStatus] = useState<number | null>(null);

  const handleDealStatusChange = (value: number) => {
    setDealStatus(value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={GeneralFormValidationSchema}
    >
      <Box as={Form} {...rest}>
        <QuerySuggestions
          query={CompanyResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('forms.input.company.label')}
              mb={4}
              name="company"
              options={value as ISelectOption[]}
              placeholder={t('forms.input.query.placeholder')}
              size="md"
              w="100%"
            />
          )}
        </QuerySuggestions>

        <FileField
          label={t('forms.input.cover.label')}
          mb={4}
          name="cover"
          size="md"
          w="100%"
        />

        <InputField
          helper={t('forms.input.seo-slug.placeholder')}
          label={t('forms.input.seo-slug.label')}
          mb={4}
          name="slug"
          size="md"
          w="100%"
        />

        <DateField
          label={t('forms.input.deadline.label')}
          mb={4}
          name="deadline"
          size="md"
          w={{ base: '100%', md: '33%' }}
        />

        <QuerySuggestions
          query={DealStatusResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('forms.input.deal-status.label')}
              mb={4}
              name="deal_status"
              options={value as ISelectOption[]}
              placeholder={t('forms.input.query.placeholder')}
              size="md"
              w={{ base: '100%', lg: '66%' }}
              onChange={(e) => {
                handleDealStatusChange(Number(e));
              }}
            />
          )}
        </QuerySuggestions>

        {dealStatus === 4 && (
          <>
            <DateField
              label={t('forms.input.closing-date.label')}
              mb={4}
              name="closing_date"
              size="md"
              w={{ base: '100%', md: '33%' }}
            />
            <TextField
              label={t('form.input.notes.label', { ns: 'common' })}
              mb={4}
              name="notes"
            />
          </>
        )}

        <InputField
          label={t('forms.input.podcast.label')}
          name="podcast"
          size="md"
          w="100%"
        />
      </Box>
    </Formik>
  );
});

GeneralForm.displayName = 'GeneralForm';
