import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { DEAL_SALE_CSV } from 'helpers/file-helpers';
import FileDownload from 'js-file-download';
import { set } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DealResources } from 'services/resources/deal-flow/deal';
import {
  IDealResource,
  IProrrataResource,
} from 'services/resources/deal-flow/deal/types.d';

interface MetricsProps {
  roundStatus: string;
}

export const SaleDealMetrics: React.FC<MetricsProps> = ({
  roundStatus,
}): JSX.Element => {
  const { deal_id } = useParams<{ deal_id: string }>();

  const [investments, setInvestments] = useState<IDealResource[]>([]);
  // const [moic, setMoic] = useState(0);
  const [totalInversionStartupMXN, setTotalInversionStartupMXN] = useState(0);
  const [salePrice, setTotalSalePrice] = useState(0);
  const [dealInfo, setDealInfo] = useState<IDealResource | null>(null);
  const [closingDate, setClosingDate] = useState('');
  const [exitDate, setExitDate] = useState('');
  const [totalInvestors, setTotalInvestors] = useState(0);
  const [slug, setSlug] = useState('');

  const deal_id_filter = Number(deal_id);

  const getInvestorName = (investment) => {
    if (
      investment.prorrata &&
      typeof investment.prorrata !== 'number' &&
      typeof investment.prorrata.user !== 'number' &&
      investment.prorrata.user?.kyc_physical_person &&
      typeof investment.prorrata.user.kyc_physical_person !== 'number'
    ) {
      const { name, first_surname, last_surname } =
        investment.prorrata.user.kyc_physical_person;
      return `${name} ${first_surname} ${last_surname}`;
    }
    return 'N/A';
  };

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        // const dealProrata = await DealResources.dealStatistics({
        //   deal_id: deal_id_filter,
        // });

        const response = await DealResources.dealsExits({
          deal_id: deal_id_filter,
        });

        console.log('response', response);
        const investmentsData = response.data.results;

        setInvestments(investmentsData);

        const dealData = investmentsData[0].deal;

        console.log('dealData', dealData);

        if (dealData && typeof dealData !== 'number') {
          setDealInfo(dealData);
        }

        // if (dealData && typeof dealData !== 'number') {
        //   const valResponse = await ValuationResources.findAll({
        //     company_id:
        //       typeof dealData.company === 'object'
        //         ? dealData.company.id
        //         : dealData.company,
        //   });
        //   console.log('valResponse', valResponse);
        //   // if (dealData.sales_value !== undefined) {
        //   //   const descriptionValue = valResponse.data.results[0]?.description;
        //   //   if (
        //   //     typeof descriptionValue === 'number' &&
        //   //     descriptionValue !== undefined
        //   //   ) {
        //   //     setMoic(dealData.sales_value / descriptionValue);
        //   //   } else {
        //   //     console.error(
        //   //       'Description value is not a number or is undefined',
        //   //     );
        //   //   }
        //   // }
        // }

        // console.log('moic', moic);

        setTotalInvestors(investmentsData.length);

        const totalInversion = investmentsData.reduce(
          (sum, investment) =>
            sum +
            (investment.prorrata && typeof investment.prorrata === 'object'
              ? (investment.prorrata as IProrrataResource)
                  .total_investment_amount ?? 0
              : 0),
          0,
        );
        setTotalInversionStartupMXN(totalInversion);
        if (dealData && typeof dealData !== 'number') {
          setTotalSalePrice(
            typeof dealData.sales_value === 'number' ? dealData.sales_value : 0,
          );
        }
        // setTotalInvestors(investmentsData.length);

        // setInvestments(investmentsData);
        // setTotalInvestors(investmentsData.length);
        // const totalRaised = investmentsData.reduce(
        //   (sum, investment) => sum + (investment.amount ?? 0),
        //   0,
        // );
        // setTotalCapitalRaised(totalRaised);
        // const totalDepositado = investmentsData.reduce(
        //   (sum, investment) => sum + (investment.total_deposit ?? 0),
        //   0,
        // );
        // setTotalDepositadoMXN(totalDepositado);
        // const totalInversion = investmentsData.reduce(
        //   (sum, investment) => sum + (investment.total_investment_amount ?? 0),
        //   0,
        // );
        // setTotalInversionStartupMXN(totalInversion);

        // const totalProrrata = investmentsData.reduce(
        //   (sum, investment) =>
        //     sum + parseFloat(String(investment.prorrata_percentage ?? '0')),
        //   0,
        // );
        // setTotalProrrataPercentage(totalProrrata);

        // const totalComision = investmentsData.reduce(
        //   (sum, investment) =>
        //     sum +
        //     ((investment.total_deposit ?? 0) -
        //       (investment.total_investment_amount ?? 0)),
        //   0,
        // );
        // setTotalComisionInversionistaMXN(totalComision);

        if (investmentsData.length > 0) {
          if (typeof dealData === 'object' && 'closing_date' in dealData) {
            setClosingDate(dealData.closing_date ?? '');
            setExitDate(dealData.exit_date ?? '');
          }
        }
        if (
          typeof dealData === 'object' &&
          dealData !== null &&
          'slug' in dealData
        ) {
          if (dealData && 'slug' in dealData) {
            setSlug(dealData.slug ?? '');
          }
        }
        // }
      } catch (error) {
        console.error('Error fetching investments:', error);
      }
    };

    fetchInvestments();
  }, [deal_id]);

  console.log('INVESTMENTS', investments);

  // ✅ Función para convertir los datos a CSV
  const downloadCSV = useCallback(async () => {
    const { data } = await DealResources.exportData(deal_id_filter);
    console.log('DATA', data);
    FileDownload(data as Blob, DEAL_SALE_CSV);
  }, []);

  return (
    <Box p={6} maxW="1200px" mx="auto">
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        Metricas de salida del Deal - {slug}
      </Heading>
      <Box display="flex" flexDirection="column" alignItems="center">
        <SimpleGrid columns={[1, null, 4]} spacing={10}>
          <Stat>
            <StatLabel>Nombre comercial del deal</StatLabel>
            <StatNumber>
              {typeof dealInfo?.company === 'object'
                ? dealInfo.company.name
                : 'N/A'}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Razon social del deal</StatLabel>
            <StatNumber>
              {typeof dealInfo?.company === 'object'
                ? dealInfo.company.business_name
                : 'N/A'}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Total Invertido</StatLabel>
            <StatNumber>
              ${totalInversionStartupMXN.toLocaleString()}
            </StatNumber>
            {/* <StatHelpText>Funding Target: $1000</StatHelpText> */}
          </Stat>

          <Stat>
            <StatLabel>Valor de venta</StatLabel>
            <StatNumber>${salePrice.toLocaleString()}</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>MOIC arkangeles</StatLabel>
            <StatNumber>1.44x</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Comisión Carry</StatLabel>
            <StatNumber>20%</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Impuesto ISR a retener</StatLabel>
            <StatNumber>20%</StatNumber>
          </Stat>
        </SimpleGrid>

        <Box mt={10} width="100%">
          <Heading as="h3" size="md" mb={4}>
            Detalles de salida
          </Heading>
          <Box overflowX="auto" width="100%">
            <Table variant="simple" minWidth="1200px">
              <Thead>
                <Tr>
                  <Th minWidth="150px">Nombre del inversionista</Th>
                  <Th minWidth="150px">Correo</Th>
                  <Th minWidth="150px">Banco</Th>
                  <Th minWidth="150px">CLABE</Th>
                  <Th minWidth="150px">RFC</Th>
                  <Th minWidth="150px">Fecha de inversion inicial</Th>
                  <Th minWidth="150px">Fecha de distribución</Th>
                  <Th minWidth="150px">Fecha de depósito</Th>
                  <Th minWidth="150px">Participación del sindicato</Th>
                  <Th minWidth="150px">Monto de inversion inicial</Th>
                  <Th minWidth="150px">Retorno Bruto</Th>
                  <Th minWidth="150px">Comisión de mercado secundario</Th>
                  <Th minWidth="150px">Carry</Th>
                  <Th minWidth="150px">Retorno después del Carry</Th>
                  <Th minWidth="150px">Impuestos retenidos</Th>
                  <Th minWidth="150px">Total a dispersar</Th>
                  <Th minWidth="150px">TIR Bruta</Th>
                  <Th minWidth="150px">MOIC BRUTO</Th>
                  <Th minWidth="150px">Tir Neta</Th>
                  <Th minWidth="150px">MOIC NETO</Th>
                </Tr>
              </Thead>
              <Tbody>
                {investments.map((investment) => (
                  <Tr key={investment.id}>
                    <Td minWidth="250px">{getInvestorName(investment)}</Td>
                    <Td minWidth="150px">
                      {investment.prorrata &&
                      typeof investment.prorrata === 'object' &&
                      investment.prorrata.user &&
                      typeof investment.prorrata.user !== 'number'
                        ? investment.prorrata.user.email
                        : 'N/A'}
                    </Td>
                    <Td>{investment.bank}</Td>
                    <Td minWidth="150px">
                      {investment.prorrata &&
                      typeof investment.prorrata === 'object' &&
                      investment.prorrata.bank_statement &&
                      typeof investment.prorrata.bank_statement === 'object' &&
                      'account_number' in investment.prorrata.bank_statement
                        ? investment.prorrata.bank_statement.account_number
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">
                      {investment.prorrata &&
                      investment.prorrata &&
                      typeof investment.prorrata !== 'number' &&
                      typeof investment.prorrata.user === 'object' &&
                      investment.prorrata.user.kyc_physical_person &&
                      typeof investment.prorrata.user.kyc_physical_person !==
                        'number'
                        ? investment.prorrata.user.kyc_physical_person.tax_id
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">
                      {investment.agreement_date
                        ? new Date(
                            investment.agreement_date,
                          ).toLocaleDateString()
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">{new Date().toLocaleDateString()}</Td>
                    <Td minWidth="150px">
                      {investment.prorrata &&
                      typeof investment.prorrata !== 'number' &&
                      investment.prorrata.deposit_date
                        ? new Date(
                            investment.prorrata.deposit_date,
                          ).toLocaleDateString()
                        : 'N/A'}
                    </Td>
                    <Td minWidth="150px">
                      {investment.union_participation !== undefined
                        ? Number(investment.union_participation).toFixed(2)
                        : 'N/A'}{' '}
                      %
                    </Td>
                    <Td minWidth="150px">
                      {`$${(
                        (typeof investment.prorrata === 'object' &&
                          investment.prorrata.total_investment_amount) ??
                        0
                      ).toLocaleString()}`}
                    </Td>
                    <Td minWidth="150px">{`$${(
                      investment.gross_return ?? 0
                    ).toLocaleString()}`}</Td>
                    <Td minWidth="150px">
                      {`$${(
                        investment.secondary_market_fee ?? 0
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                    </Td>
                    <Td minWidth="150px">
                      {`$${(investment.carry ?? 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                    </Td>
                    <Td minWidth="150px">
                      {`$${(investment.return_after_carry ?? 0).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}`}
                    </Td>
                    <Td minWidth="150px">
                      {`$${(investment.taxes_withheld ?? 0).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}`}
                    </Td>
                    {/* <Td minWidth="150px">
                      {(investment.return_after_fees ?? 0).toLocaleString()}
                    </Td> */}
                    <Td minWidth="150px">
                      {`$${(investment.total_to_disperse ?? 0).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}`}
                    </Td>
                    <Td minWidth="150px">
                      {Number(investment.gross_irr).toFixed(2)
                        ? Number(investment.gross_irr).toFixed(2)
                        : 'N/A'}{' '}
                      %
                    </Td>
                    <Td minWidth="150px">
                      {Number(investment.gross_moic).toFixed(2)} x
                    </Td>
                    <Td minWidth="150px">
                      {investment.net_tir ? investment.net_tir : 'N/A'} %
                    </Td>
                    <Td minWidth="150px">
                      {Number(investment.net_moic).toFixed(2)} x
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Td colSpan={3}>Total</Td>
                  <Td colSpan={14} />
                </Tr>
              </Tfoot>
            </Table>
          </Box>
        </Box>
      </Box>

      <Box
        position="sticky"
        top={0}
        zIndex={10}
        bg="white"
        p={4}
        boxShadow="sm"
      >
        <Button colorScheme="blue" onClick={downloadCSV}>
          Descargar CSV
        </Button>
      </Box>
    </Box>
  );
};

export default SaleDealMetrics;
