import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormLabel,
  Heading,
  Input,
  Select,
} from '@chakra-ui/react';
import { MoneyField, PercentageField } from 'components/inputs';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { BuyersTable } from './BuyersTable';
import { SaleTable } from './SaleTable';
import SecondaryMarket from './SecondaryMarket';
import { ShareBuybackApplicant } from './ShareBuybackApplicant';

const CombinedExit = (props) => {
  const { saleValueSM = 0, saleValueRebuy = 0 } = props;
  const { setFieldValue, values } = useFormikContext<any>();

  useEffect(() => {
    console.log('values in combined exit', values);
    setFieldValue('sale_type', 'combined'); // Set sale_type to "buyback" only on mount
  }, []);

  const [buyers, setBuyers] = useState([
    { user: null, percentage: 0, montoCompra: '' },
  ]);

  // Formato de moneda
  const parseCurrency = (value) => Number(value.replace(/[^0-9.-]+/g, ''));

  // Handler para el cambio de valor del monto de venta
  const changeHandler = (e) => {
    const salesValue = parseCurrency(e);
    setFieldValue('sales_value', salesValue);
  };

  // Calculamos el monto para recompra
  const amountForRebuy = (e) => {
    const percentage = e.target.value;
    console.log('sales_value rebuy', values.sales_value);
    const result = values.sales_value * (percentage / 100);
    setFieldValue('rebuy_percentage', result);
  };

  // Calculamos el monto para el mercado secundario
  const amountForSM = (e) => {
    const percentage = e.target.value;
    console.log('sales_value sm', values.sales_value);
    const result = values.sales_value * (percentage / 100);
    setFieldValue('sm_percentage', result);
  };

  useEffect(() => {
    // Cuando los porcentajes cambian, recalcular los montos correspondientes
    if (values.rebuy_percentage > 0 && values.sm_percentage > 0) {
      const totalSale = values.rebuy_percentage + values.sm_percentage;
      if (totalSale > values.sales_value) {
        alert(
          'El total de recompra y mercado secundario excede el monto total de la venta.',
        );
      }
    }
  }, [values.rebuy_percentage, values.sm_percentage, values.sales_value]);

  return (
    <>
      <Box>
        <FormLabel textAlign="left">Porcentaje de venta</FormLabel>
        <PercentageField
          name="sale_percentage"
          size="md"
          w={{ base: '100%', lg: '33%' }}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value.replace('%', '');
            setFieldValue('sales_percentage', value);
          }}
        />
      </Box>
      <Box>
        <FormLabel textAlign="left">Monto total de venta</FormLabel>
        <MoneyField
          mb={4}
          name="sales_value"
          size="md"
          w={{ base: '100%', lg: '66%' }}
          onChange={(e) => changeHandler((e.target as HTMLInputElement).value)}
        />
      </Box>
      <Flex>
        <Box p={7}>
          <FormLabel textAlign="left">Porcentaje para recompra</FormLabel>
          <Input
            name="rebuy_percentage"
            type="number"
            min={0}
            max={100}
            onChange={(e) => setFieldValue('rebuy_percentage', e.target.value)}
          />
        </Box>

        <Box p={7}>
          <FormLabel textAlign="left">
            Porcentaje para mercado secundario
          </FormLabel>
          <Input
            name="sm_percentage"
            type="number"
            min={0}
            max={100}
            onChange={(e) => setFieldValue('sm_percentage', e.target.value)}
          />
        </Box>
      </Flex>

      <Box>
        <FormLabel>Tipo de salida</FormLabel>
        <Select
          name="exit_type"
          placeholder="Tipo de salida"
          onChange={(e) => setFieldValue('exit_type', e.target.value)}
          disabled={!values.sales_value || Number(values.sales_value) <= 0}
        >
          <option value="total">Total</option>
          <option value="parcial">Parcial</option>
        </Select>
      </Box>

      {values.exit_type === 'total' && (
        <>
          <SaleTable
            saleValueSM={saleValueSM}
            saleValueRebuy={saleValueRebuy}
            config={{
              saleType: 'sm',
              tableType: 'total',
            }}
          />
          <BuyersTable
            buyers={buyers}
            setBuyers={setBuyers}
            salesValue={values.sales_value}
            setFieldValue={setFieldValue}
          />
        </>
      )}

      {values.exit_type === 'parcial' && (
        <>
          <SaleTable
            saleValueSM={saleValueSM}
            saleValueRebuy={saleValueRebuy}
            config={{
              saleType: 'sm',
              tableType: 'parcial',
            }}
          />
          <BuyersTable
            buyers={buyers}
            setBuyers={setBuyers}
            salesValue={values.sales_value}
            setFieldValue={setFieldValue}
          />
        </>
      )}

      {/* <SaleTable
        combinedExit
        config={{
          saleType: 'buyback',
          tableType: 'parcial',
        }}
      /> */}

      {/* <Accordion>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Recompra
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Heading as="h4" size="md">
              {`Recompra - Monto total de venta $${values.rebuy_percentage
                .toFixed(2)
                .toLocaleString()}`}
            </Heading>
            <ShareBuybackApplicant
              combinedExit
              saleValueSM={values.sm_percentage}
              saleValueRebuy={values.rebuy_percentage}
              saleAmountIntput={false}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Mercado secundario
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Heading as="h4" size="md">
              {`Mercado Secundario - Monto total de venta $${values.sm_percentage}`}
            </Heading>
            <SecondaryMarket
              combinedExit
              saleValueSM={values.sm_percentage}
              saleValueRebuy={values.rebuy_percentage}
              saleAmountIntput={false}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion> */}
    </>
  );
};

export default React.memo(CombinedExit);
