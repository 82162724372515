import { id } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { DealResources } from 'services/resources/deal-flow/deal';
import { IInvestmentResource } from 'services/resources/financial/investment/types';
import { IUserResource } from 'services/resources/users/user/types.d';

export const useFetchInvestments = (deal_id_filter, sales_value) => {
  const [investmentData, setInvestmentData] = useState<
    {
      id: number;
      investmentId: number | IInvestmentResource | undefined | string;
      usuario: number | IUserResource | undefined | string;
      prorrata: any;
      montoVenta: number | undefined;
      agreement_date?: string;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInvestments = async () => {
    if (!deal_id_filter) return; // Verifica que deal_id_filter esté definido antes de intentar la solicitud

    setIsLoading(true);
    try {
      const response = await DealResources.dealStatistics({
        deal_id: deal_id_filter,
      });
      console.log('response', response);
      const investmentsData = response.data.results;
      console.log('investmentsData', investmentsData);
      const registros = investmentsData.map((investmentProrrata) => ({
        id: investmentProrrata.id ?? 0,
        investmentId:
          typeof investmentProrrata?.investment === 'object'
            ? investmentProrrata?.investment?.id
            : undefined,
        usuario: investmentProrrata?.user,
        prorrata: investmentProrrata.prorrata_percentage,
        montoVenta: investmentProrrata.prorrata_percentage
          ? (investmentProrrata.prorrata_percentage * sales_value) / 100
          : 0,
        agreement_date: investmentProrrata.agreement_date,
      }));
      setInvestmentData(registros); // Guarda los datos obtenidos en el estado
    } catch (error) {
      console.error('Error fetching investments:', error);
    } finally {
      setIsLoading(false); // Finaliza el indicador de carga
    }
  };

  // Llama a fetchInvestments cuando deal_id_filter cambia
  useEffect(() => {
    fetchInvestments();
  }, [deal_id_filter]);

  return { investmentData, isLoading };
};

// export default useFetchInvestments;
